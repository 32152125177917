<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Live vs Optimiser">
            <div class="row">
                <div class="col-12">
                    <b-card no-body class="tabbed-card">
                        <b-tabs card>
                            <b-tab title="Line" active>
                                <live-vs-replay type="line"></live-vs-replay>
                            </b-tab>
                            <b-tab title="Bar">
                                <live-vs-replay type="column"></live-vs-replay>
                            </b-tab>
                            <div class="row">
                                <div class="col-md-4 text-center">
                                    <p class="replay-chart-key">Optimiser</p>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p class="tolerance-chart-key">Live With Perfect Executions</p>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p class="live-chart-key">Live Returns</p>
                                </div>
                                <div class="col-12 text-center">
                                    <p>Operating perfectly, we would expect to see Black Line greater than blue,
                                        with blue being approximately the same as green.</p> <!--WHY DO YOU EXIST IF NOT SHOWN-->
                                </div>
                            </div>
                            <live-vs-replay tableOnly></live-vs-replay>
                        </b-tabs>
                    </b-card>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import LiveVsReplay from '@/widgets/LiveVsReplay';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        LiveVsReplay
    }
}
</script>

<style lang="scss">
.replay-chart-key:before {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #7cb5ec;
    display: inline-block;
    margin-right: 5px;
}

.tolerance-chart-key:before {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #90ed7d;
    display: inline-block;
    margin-right: 5px;
}

.live-chart-key:before {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #434348;
    display: inline-block;
    margin-right: 5px;
}
</style>