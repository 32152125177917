<template>
    <div class="live-vs-replay-widget">
        <div v-if="chartOnly">
            <chart @getSVG="getSVG" v-if="chartOptions.series.length > 0" :chartOptions="chartOptions" :chartID="chartID" ></chart>
        </div>
        <div v-if="tableOnly">
            <div class="col-12 d-flex flex-column align-items-center mb-2" style="margin-top:15px">
               <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty>
                   <template slot="empty">
                       No results found
                   </template>
               </b-table>
           </div>
        </div>
        <div v-if="!tableOnly && !chartOnly">
            <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart.vue'

export default {
    name: 'live-vs-replay',

    data() {
        let exportingOptions = {
            filename: "LiveVsReplay",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Live vs Replay"}
            },
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        return {
            dateRange: {
                startDate: new Date(`${new Date(Date.now()).getFullYear().toString()}-01-01`),
                endDate: new Date(Date.now())
            },
            chartOptions: {
                exporting: exportingOptions,
                chart: {
                    type: this.type,
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    
                    labels: {
                        overflow: 'justify',
                        formatter: function () {
                            return this.value + '%';
                            
                        }
                    },
                    allowDecimals: false
                },
                plotOptions: {
                    line: {
                        marker: {//Changed from false to true to allow for points to be marked.
                            enabled: true,
                            symbol: 'circle'
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: []
            },
            hasSetupListeners: false,
            tableOptions:{
                columns:[
                    {
                        key: 'name',
                        label: '',
                    },
                    {
                        key: 'YTD',
                        label: 'YTD',
                    },
                    {
                        key: 'MTD',
                        label: 'MTD',
                    },
                    {
                        key: 'WTD',
                        label: 'WTD',
                    },
                    {
                        key: 'Variance',
                        label: 'YTD Variance',
                    }
                ],
                items:[]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '400px'
        },
        type: {
            type: String,
            default: 'line'
        },
        tableOnly:{
            type:Boolean,
            default:false
        },
        chartOnly:{
            type: Boolean,
            default: false
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        chartID:{
            type: String,
            default:"0"
        },
        ID:{
            type:Number,
            default:null
        }
    },
    components:{
        DateRangePicker,
        chart
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('replayreturnscomparison', this.onReplayReturnsComparison);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('replayreturnscomparison', this.onReplayReturnsComparison);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            //if parsed dates are greater than 2 weeks and in the same month then update chart axis
            let dateDifference =  Math.floor(( Date.parse(this.dateEnd) - Date.parse(this.dateStart) ) / 86400000);
            if(this.dateEnd != null && this.dateEnd.getMonth() == this.dateStart.getMonth() && dateDifference > 14){
                this.chartOptions.xAxis = {
                    type: 'datetime',
                    min:Date.UTC(this.dateStart.getFullYear(), this.dateStart.getMonth(), this.dateStart.getDate()),
                    max:Date.UTC(this.dateEnd.getFullYear(), this.dateEnd.getMonth(), this.dateEnd.getDate()),

                }
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'ReplayReturnsComparison',
                args: {
                    Date: this.dateEnd == null ? format(this.dateRange.endDate, 'yyyy-MM-dd') : format(this.dateEnd, 'yyyy-MM-dd'),
                }
            });
        },
        getSVG(svg){
            this.$emit("getSVG", svg , this.ID);
        },
        accountToName(account){
            account = account.toLowerCase()
            let response = "";
            if(account == "replay"){
                response = "Optimiser"
            }else if(account.includes("perfect executions")){
                response = "Live With Perfect Executions"
            }else{
                response = "Live"
            }  
            return response
        },
        createRow(name, obj, variance){
            let multiplier = 100;
            let decimal = 2;
            let YTD = (obj.YTD * multiplier).toFixed(decimal)
            let MTD = (obj.MTD * multiplier).toFixed(decimal)
            let WTD = (obj.WTD * multiplier).toFixed(decimal)
            variance = isNaN(variance) ? variance : ((variance * multiplier).toFixed(decimal) + "%")
            return {
                    "name": name,
                    "YTD":  YTD+ "%",
                    "MTD": MTD+ "%",
                    "WTD": WTD+ "%",
                    "Variance": variance,
                }
        },
        onReplayReturnsComparison(event) {
           
            if(this.tableOnly){
                let tableData = event.response.table
                let rows = [];
                
                for(let obj in tableData){
                    let variance = tableData["liveSum"].YTD - tableData[obj].YTD;
                    let name = "Live With Perfect Executions"
                    let index = 1;
                    if(obj.toLowerCase() == "optimisersum"){
                        name = "Optimiser"
                        index = 2;
                    }else if (obj.toLowerCase() == "livesum"){
                        name = "Live"
                        variance = "-"
                        index = 0;
                    }
                    rows[index] = (this.createRow(name, tableData[obj], variance))
                }

                this.tableOptions.items = rows

                this.$emit("getData", rows, this.ID)
            }
            if((!this.chartOnly && !this.tableOnly) || this.chartOnly){
                let data = event.response.chart
                let dates = Object.keys(data).map(function(date) {
                    return new Date(date);
                }).sort(compareAsc);

                let formattedDates = {};
                for(let date of dates) {
                    formattedDates[`${format(date, 'yyyy-MM-dd')}T00:00:00`] = date;
                }

                let accounts = [];
                for(let date in formattedDates) {
                    for(let account in data[date]) {
                        if(accounts.indexOf(account) === -1) {
                            accounts.push(account);
                        }
                    }
                }

                let rows = [];
                for(let account of accounts) {
                    let row = {
                        name: this.accountToName(account),
                        data: []
                    };

                    if(this.type === 'line') {
                        let cumulative = 0;
                        let first = true;
                        for(let date in formattedDates) {
                            if(first) {
                                let firstDate = subBusinessDays(formattedDates[date], 1);
                                row.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                                first = false;
                            }

                            if(data[date].hasOwnProperty(account)) {
                                cumulative += parseFloat(data[date][account]) * 100;
                            }

                            row.data.push([Date.UTC(formattedDates[date].getFullYear(), formattedDates[date].getMonth(), formattedDates[date].getDate()), cumulative]);
                        }
                    } else {
                        for(let date in formattedDates) {
                            if(data[date].hasOwnProperty(account)) {
                                row.data.push([Date.UTC(formattedDates[date].getFullYear(), formattedDates[date].getMonth(), formattedDates[date].getDate()), parseFloat(data[date][account]) * 100]);
                            }
                        }
                    }

                    rows.push(row);
                }

                this.chartOptions.series = rows;
            }
        }
    }
}
</script>